import moment from 'moment';
import { STATUS_BADGE } from '../../../../../constants/fundings';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useTableContext } from '../../../../../context/table-context';

function ConsumerClosedFundingsWidget({
  closedFundings,
  consumerId,
  consumerFirstName,
  consumerLastName,
}) {
  const navigate = useNavigate();

  const { getTable, updateTable, setSelectedConsumerFilter, setSelectedFundingIdsFilter } =
    useTableContext();

  return (
    <div className="bo-border-container bg-light rounded w-100 p-0">
      <div className="p-2">
        <div className="d-flex justify-content-between align-items-center">
          <p className="bo-consumer-text-bold">Recently Closed Advances</p>
        </div>
        {closedFundings.map(funding => (
          <div className="mt-2 d-flex justify-content-between" key={funding.id}>
            <div className="d-flex">
              <Link to={`/funding/${funding.id}`} className="bo-consumer-link bo-w-80">
                #{funding.id.toString().padStart(6, '0')}
              </Link>
              <p className="bo-consumer-text bo-w-60">{`$${funding.amount}`}</p>
              <p className="bo-consumer-text bo-w-80">{`${moment(funding.closeTimestamp).diff(
                moment(funding.createTimestamp),
                'days',
              )} days`}</p>
            </div>
            <p className="bo-consumer-text text-truncate">
              {`${STATUS_BADGE[funding.status]?.text} ${moment
                .utc(funding.closeTimestamp)
                .fromNow()}`}
            </p>
          </div>
        ))}
        <Button
          className="mt-1 bo-consumer-inline-button text-decoration-none"
          onClick={() => {
            setSelectedConsumerFilter({
              consumerId,
              consumerFirstName,
              consumerLastName,
              message: `${consumerFirstName} ${consumerLastName} (${consumerId}) → All Advances`,
            });

            const table = getTable({
              tableName: 'fundings',
              initSortBy: 'id',
              initSortOrder: 'desc',
              initRowsPerPage: 25,
              initStatusFilter: '',
            });

            updateTable({ tableName: 'fundings', newTable: { ...table, statusFilter: '' } });

            setSelectedFundingIdsFilter({ fundingIds: null, message: null });

            navigate('/fundings');
          }}
        >
          Show all ...
        </Button>
      </div>
    </div>
  );
}

export default ConsumerClosedFundingsWidget;
