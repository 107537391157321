import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../../components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useEnv } from '../../context/env-context';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import Rules from './tabs/Rules/Rules';
import Permissions from './tabs/Permissions/Permissions';
import GlobalSettings from './tabs/GlobalSettings/GlobalSettings';

const SIDEBAR = {
  rules: { name: 'Rules' },
  'referral-campaigns': { name: 'Referral Campaigns', disabled: true },
  'popup-messages': { name: 'Popup Messages', disabled: true },
  rewards: { name: 'Rewards', disabled: true },
  settings: {
    name: 'Settings',
    nested: {
      'banking-holidays': { name: 'Banking Holidays', disabled: true },
      'global-settings': { name: 'Global Settings' },
    },
  },
  permissions: { name: 'Permissions' },
};

function Settings() {
  const params = useParams();
  const navigate = useNavigate();

  const [openedAccordions, setOpenedAccordions] = useState([]);

  let permissions;

  const { user } = useAuth();
  const { audience } = useEnv();

  if (user) {
    permissions = user[`${audience}/metadata`].permissions || [];
  } else {
    permissions = [];
  }

  const getRouteName = (obj, route) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      if (acc) return acc;
      if (key === route && value.name) return value.name;
      if (typeof value === 'object' && value !== null) {
        return getRouteName(value.nested || value, route);
      }

      return acc;
    }, null);

  return (
    <div className="d-flex h-100">
      <div className="bo-w-300 border-2 border-end d-flex flex-column">
        <div className="mt-3 mb-3">
          <h2 className="mx-4">Settings</h2>
          <div className="mt-4 d-flex flex-column">
            {Object.entries(SIDEBAR).map(([key, value]) => {
              if (value.nested) {
                return (
                  <div key={key}>
                    <Button
                      className="bo-sidebar-accordion"
                      onClick={() =>
                        setOpenedAccordions(prev =>
                          prev.includes(key) ? prev.filter(x => x !== key) : [...prev, key],
                        )
                      }
                      disabled={value.disabled}
                    >
                      <div className="bo-sidebar-accordion-icon">
                        <FontAwesomeIcon
                          icon={openedAccordions.includes(key) ? faAngleDown : faAngleRight}
                          size="lg"
                        />
                      </div>
                      <p className="p-0 m-0">{value.name}</p>
                    </Button>
                    {openedAccordions.includes(key) && (
                      <div className="w-100">
                        {Object.entries(value.nested).map(([nKey, nValue]) => (
                          <Button
                            key={nKey}
                            className={classNames(
                              'bo-sidebar-button fw-normal',
                              params?.tab === nKey && 'bo-sidebar-button-active',
                            )}
                            onClick={() => navigate(`/settings/${nKey}`)}
                            disabled={nValue.disabled}
                          >
                            <p className="p-0 m-0 ps-3">{nValue.name}</p>
                          </Button>
                        ))}
                      </div>
                    )}
                  </div>
                );
              }

              return (
                <Button
                  key={key}
                  className={classNames(
                    'bo-sidebar-button',
                    params?.tab === key && 'bo-sidebar-button-active',
                  )}
                  onClick={() => navigate(`/settings/${key}`)}
                  disabled={value.disabled}
                >
                  <p className="p-0 m-0"> {value.name}</p>
                </Button>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex-fill overflow-auto d-flex flex-column">
        <div className="my-4 mx-2">
          <h5 className="ps-1">{getRouteName(SIDEBAR, params?.tab)}</h5>
        </div>
        {params?.tab === 'rules' && permissions.includes('Rules Management') && <Rules />}
        {params?.tab === 'permissions' && permissions.includes('Admin') && <Permissions />}
        {params?.tab === 'global-settings' && permissions.includes('Admin') && <GlobalSettings />}
      </div>
    </div>
  );
}

export default withAuthenticationRequired(Settings, { onRedirecting: () => <Loading /> });
