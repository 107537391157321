import { Badge, Button } from 'reactstrap';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { Spinner } from '../../../../../components/Spinner';
import { useState } from 'react';

function ConsumerBankAccountsSection({ consumerId, bankAccounts, setBankAccounts }) {
  const [spinner, setSpinner] = useState(false);

  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const setAsMain = async plaidAccountId => {
    if (window.confirm('Are you sure?')) {
      try {
        setSpinner(true);
        const token = await getAccessTokenSilently();

        if (!token) {
          return;
        }

        const config = {
          token,
          url: `${apiOriginConsumer}/set_main_account`,
          method: 'POST',
          data: JSON.stringify({ consumerId, plaidAccountId }),
        };

        const response = await makeRequest(config);

        if (response === 'ok') {
          toast.success('Main account succesfuly set.');
        } else {
          toast.success(`Main account set, but funding source not created. Error: ${response}`);
        }

        setBankAccounts(prev =>
          prev.map(x =>
            x.plaidAccountId === plaidAccountId ? { ...x, isMain: true } : { ...x, isMain: false },
          ),
        );
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  return (
    <div className="w-100">
      <Spinner visible={spinner}>
        <table className="w-100">
          <colgroup>
            <col width="31%" />
            <col width="23%" />
            <col width="23%" />
            <col width="23%" />
          </colgroup>
          <tbody>
            {bankAccounts.map(bankAccount => (
              <tr key={bankAccount.plaidAccountId} className="bo-h-30">
                <td className="bo-consumer-text text-truncate bo-max-w-1">{bankAccount.name}</td>
                <td className="bo-consumer-text ps-2 text-nowrap">{bankAccount.subType}</td>
                <td className="bo-consumer-text ps-2 text-nowrap">{`****${bankAccount.mask}`}</td>
                <td className="text-nowrap ps-2 pb-1">
                  {bankAccount.isMain ? (
                    <Badge color="success" className="bo-consumer-inline-badge py-1">
                      Main
                    </Badge>
                  ) : (
                    <Button
                      className="bo-consumer-inline-button p-0 m-0"
                      onClick={() => setAsMain(bankAccount.plaidAccountId)}
                    >
                      Set Main
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Spinner>
    </div>
  );
}

export default ConsumerBankAccountsSection;
