import { Button, Container } from 'reactstrap';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { useState, useEffect, useMemo } from 'react';
import { makeRequest } from '../../../../utils/makeRequest';
import { Spinner } from '../../../../components/Spinner';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedList from '../../../../components/VirtualizedList';
import GlobalSettingsModal from './components/modals/GlobalSettingsModal';

function GlobalSettings() {
  const { apiOriginOrchestrator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [globalSettingsModal, setGlobalSettingsModal] = useState(null);
  const [settings, setSettings] = useState([]);

  const getSettings = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/settings/get`,
      method: 'GET',
    };

    try {
      const response = await makeRequest(config);

      setSettings(response.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ReactModal.setAppElement(document.getElementById('root'));

  const tableColumns = useMemo(
    () => [
      {
        key: 'name',
        header: 'Name',
        width: 300,
      },
      {
        key: 'value',
        header: 'Value',
        width: 500,
        renderValue: value => {
          if (typeof value === 'boolean') {
            return value ? 'YES' : 'NO';
          }

          return value;
        },
      },
      {
        key: 'key',
        header: '',
        width: 100,
        renderValue: (_, row) => (
          <Button className="bo-consumer-inline-button" onClick={() => setGlobalSettingsModal(row)}>
            Edit
          </Button>
        ),
      },
    ],
    [],
  );

  const closeGlobalSettingModal = () => setGlobalSettingsModal(null);

  return (
    <div className="d-flex flex-column flex-grow-1">
      <Spinner visible={spinner} wrapperStyle={{ height: '100%', paddingBottom: 48 }}>
        <Container className="h-100">
          <AutoSizer>
            {({ height, width }) => (
              <VirtualizedList
                tableRows={settings}
                tableColumns={tableColumns}
                width={width}
                height={height}
                longMultilineColumnKey="value"
                rowKey="key"
              />
            )}
          </AutoSizer>
        </Container>
      </Spinner>
      <GlobalSettingsModal
        closeModal={closeGlobalSettingModal}
        globalSettingsModal={globalSettingsModal}
        setSettings={setSettings}
      />
    </div>
  );
}

export default GlobalSettings;
