import classNames from 'classnames';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { areEqual, VariableSizeList } from 'react-window';

const SCROLLBAR_SIZE = 10;

const HeaderRow = memo(
  ({
    index,
    style,
    data: {
      getColumnWidth,
      headerData,
      cellPaddingLeftSize = 3,
      headerTopBorder,
      headerLeftBorder,
      extendLastColumn,
      addWidthToLastColumn,
    },
  }) => (
    <div style={{ ...style, width: undefined, display: 'flex' }}>
      {headerData[index].map((col, colIdx) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={colIdx}
          style={{
            minWidth:
              getColumnWidth(colIdx) +
              (headerData[index].length - 1 === colIdx && addWidthToLastColumn
                ? SCROLLBAR_SIZE
                : 0),
          }}
          className={classNames(
            `bo-table-cell fw-bold ps-${cellPaddingLeftSize} pe-1 overflow-hidden border-bottom`,
            headerLeftBorder && colIdx !== 0 && col && !col.isPlaceholder && 'border-start',
            headerTopBorder && 'border-top',
            !extendLastColumn && headerData[index].length - 1 === colIdx && 'border-end',
            col && col.className,
          )}
        >
          {col && !col.isPlaceholder ? col.header : ''}
        </div>
      ))}
    </div>
  ),
  areEqual,
);

const Row = memo(
  ({
    index,
    style,
    data: {
      tableRows,
      tableColumns,
      getColumnWidth,
      cellPaddingLeftSize = 3,
      rowClickable,
      activeRowIndex,
      setActiveRowIndex,
      hovering,
      manualHovering,
      customHoverClass,
      formatCellConditionally,
      handleRowRightClick,
      addHeightToLastRow,
    },
  }) => {
    const cellClassName = `bo-table-cell ps-${cellPaddingLeftSize} pe-1 overflow-hidden`;

    const cellStyle = useCallback(
      (colIdx, cell, row, rowIdx) => {
        let newStyle = { width: getColumnWidth(colIdx) };

        if (formatCellConditionally) {
          newStyle = {
            ...newStyle,
            ...formatCellConditionally(cell, row, rowIdx, tableColumns[colIdx].key),
          };
        }

        return newStyle;
      },
      [getColumnWidth, formatCellConditionally, tableColumns],
    );

    const onMouseAction = useCallback(
      (rowIdx, action) => {
        if (manualHovering) {
          const rows = document.getElementsByClassName(`row-${rowIdx}`);

          if (rows.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const row of rows) {
              if (action === 'enter') {
                row.classList.add(customHoverClass || 'bo-table-hover-bg');
              } else {
                row.classList.remove(customHoverClass || 'bo-table-hover-bg');
              }
            }
          }
        }

        return undefined;
      },
      [manualHovering, customHoverClass],
    );

    return (
      /* eslint-disable */
      <div
        style={{
          ...style,
          height:
            addHeightToLastRow && index === tableRows.length - 1
              ? style.height + SCROLLBAR_SIZE
              : style.height,
          paddingBottom: addHeightToLastRow && index === tableRows.length - 1 ? SCROLLBAR_SIZE : 0,
          width: undefined,
          display: 'flex',
        }}
        className={classNames(
          manualHovering && `row-${index}`,
          hovering && !manualHovering && (customHoverClass || 'bo-table-row-hover'),
          activeRowIndex === index && 'bo-table-active-bg',
        )}
        onClick={
          rowClickable
            ? () => setActiveRowIndex(prevIndex => (prevIndex === index ? null : index))
            : undefined
        }
        onContextMenu={e => {
          if (handleRowRightClick) {
            handleRowRightClick(e, tableRows[index]);
          }
        }}
        onMouseEnter={() => onMouseAction(index, 'enter')}
        onMouseLeave={() => onMouseAction(index, 'leave')}
      >
        {tableColumns.map(({ key, renderValue }, colIdx) => (
          <div
            key={key}
            style={cellStyle(colIdx, tableRows[index][key], tableRows[index], index)}
            className={cellClassName}
          >
            {renderValue
              ? renderValue(tableRows[index][key], tableRows[index])
              : tableRows[index][key]}
          </div>
        ))}
      </div>
      /* eslint-enable */
    );
  },
  areEqual,
);

function VirtualizedList({
  tableRows,
  frozenColumns = [],
  frozenHeaderConfig,
  tableColumns,
  headerConfig,
  width,
  height,
  rowKey,
  rowHeight = 36,
  longMultilineColumnKey,
  previewColumn,
  getRowHeight,
  headerHeight = 36,
  headerTopBorder,
  headerLeftBorder,
  cellPaddingLeftSize,
  extendLastColumn = true,
  overscanRowCount = 10,
  hovering = true,
  customHoverClass,
  formatCellConditionally,
  handleRowRightClick,
  virtualization = true,
}) {
  const [activeRowIndex, setActiveRowIndex] = useState(null);

  const manualHovering = useMemo(
    () => hovering && frozenColumns.length > 0,
    [hovering, frozenColumns],
  );

  const outerContainerRef = useRef(null);
  const headerGridRef = useRef(null);
  const mainGridRef = useRef(null);
  const mainGridContainerRef = useRef(null);
  const frozenGridRef = useRef(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { current: outerGrid } = outerContainerRef;

    if (outerGrid) {
      const handler = e => {
        e.preventDefault();
        const { deltaX, deltaY } = e;

        const { current: header } = headerGridRef;
        const { current: gridDiv } = mainGridContainerRef;
        const { current: frozen } = frozenGridRef;

        const hiddenHeightContainer = gridDiv?.firstElementChild;
        const hiddenWidthContainer = hiddenHeightContainer?.firstElementChild;

        if (gridDiv && hiddenHeightContainer && hiddenWidthContainer) {
          let { scrollLeft, scrollTop } = gridDiv;

          if (Math.abs(deltaY) > Math.abs(deltaX)) {
            const maxVScroll = hiddenHeightContainer.clientHeight - gridDiv.clientHeight;

            scrollTop = scrollTop + deltaY < maxVScroll ? scrollTop + deltaY : maxVScroll;
          } else {
            const maxHScroll = hiddenWidthContainer.clientWidth - gridDiv.clientWidth;

            scrollLeft = scrollLeft + deltaX < maxHScroll ? scrollLeft + deltaX : maxHScroll;
          }

          if (header) {
            header.scrollLeft = scrollLeft;
          }

          if (frozen) {
            frozen.scrollTo(scrollTop);
          }

          gridDiv.scrollLeft = scrollLeft;
          gridDiv.scrollTop = scrollTop;
        }
      };

      outerGrid.addEventListener('wheel', handler);

      return () => outerGrid.removeEventListener('wheel', handler);
    }
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { current: gridDiv } = mainGridContainerRef;

    if (gridDiv) {
      const handler = e => {
        e.preventDefault();

        if (headerGridRef.current) {
          headerGridRef.current.scrollLeft = e.target.scrollLeft;
        }

        if (frozenGridRef.current) {
          frozenGridRef.current.scrollTo(e.target.scrollTop);
        }
      };

      gridDiv.addEventListener('scroll', handler);

      return () => gridDiv.removeEventListener('scroll', handler);
    }
  }, [virtualization]);

  useEffect(() => {
    mainGridRef.current?.resetAfterIndex(0);
    frozenGridRef.current?.resetAfterIndex(0);
  }, [tableRows]);

  const contentHeight = useMemo(
    () =>
      getRowHeight
        ? tableRows.reduce((acc, _, idx) => getRowHeight(idx) + acc, 0)
        : tableRows.length * rowHeight,
    [tableRows, getRowHeight, rowHeight],
  );
  const contentWidth = useMemo(
    () => tableColumns.reduce((acc, value) => acc + value.width, 0),
    [tableColumns],
  );
  const frozenContentWidth = useMemo(
    () => frozenColumns.reduce((acc, value) => acc + value.width, 0),
    [frozenColumns],
  );

  const estimatedRowHeight = useMemo(
    () => Math.round(contentHeight / tableRows.length),
    [contentHeight, tableRows],
  );

  const mainGridWidth = useMemo(() => {
    if (!extendLastColumn && contentWidth + frozenContentWidth + SCROLLBAR_SIZE < width) {
      return contentWidth + SCROLLBAR_SIZE;
    }

    let w = width;

    if (activeRowIndex !== null && !!previewColumn) {
      w -= previewColumn.width;
    }

    if (frozenColumns.length > 0) {
      w -= frozenContentWidth;
    }

    return w;
  }, [
    extendLastColumn,
    width,
    contentWidth,
    activeRowIndex,
    previewColumn,
    frozenColumns,
    frozenContentWidth,
  ]);

  const headerRowCount = headerConfig ? headerConfig.length : 1;

  const isVerticalScrollVisable = contentHeight + headerHeight * headerRowCount > height;
  const isHorizontalScrollVisable = contentWidth + frozenContentWidth > width;

  const getHeaderData = useCallback((cols, config) => {
    if (!config) {
      return [
        cols.map(col => ({
          header: col.header,
          className: col.className,
        })),
      ];
    }

    const data = [];

    for (let rowIndex = 0; rowIndex < config.length; rowIndex += 1) {
      const rowConfig = config[rowIndex];
      const rowData = [];

      let colIndex = 0;

      rowConfig.forEach(cellConfig => {
        const { header, className, colspan = 1 } = cellConfig;

        rowData[colIndex] = {
          header,
          className,
        };

        for (let i = 1; i < colspan; i += 1) {
          colIndex += 1;
          rowData[colIndex] = {
            header: '',
            className,
            isPlaceholder: true,
          };
        }

        colIndex += 1;
      });

      data.push(rowData);
    }

    return data;
  }, []);

  const getColumnWidth = useCallback(
    index => {
      if (
        width >
        (activeRowIndex !== null && !!previewColumn
          ? contentWidth + previewColumn.width
          : contentWidth)
      ) {
        if (
          longMultilineColumnKey &&
          index === tableColumns.findIndex(x => x.key === longMultilineColumnKey)
        ) {
          return (
            width -
            (activeRowIndex !== null && !!previewColumn ? previewColumn.width : 0) -
            contentWidth -
            (isVerticalScrollVisable ? SCROLLBAR_SIZE : 0) +
            tableColumns.find(x => x.key === longMultilineColumnKey).width
          );
        }

        if (!longMultilineColumnKey && extendLastColumn && index === tableColumns.length - 1) {
          return (
            width -
            (activeRowIndex !== null && !!previewColumn ? previewColumn.width : 0) -
            contentWidth -
            (isVerticalScrollVisable ? SCROLLBAR_SIZE : 0) +
            tableColumns[tableColumns.length - 1].width
          );
        }
      }

      return tableColumns[index].width;
    },
    [
      width,
      extendLastColumn,
      tableColumns,
      activeRowIndex,
      previewColumn,
      contentWidth,
      isVerticalScrollVisable,
      longMultilineColumnKey,
    ],
  );

  const frozenHeaderGridData = useMemo(
    () => ({
      getColumnWidth: index => frozenColumns[index].width,
      headerData: getHeaderData(frozenColumns, frozenHeaderConfig),
      cellPaddingLeftSize,
      headerTopBorder,
      headerLeftBorder,
      extendLastColumn,
    }),
    [
      getHeaderData,
      frozenHeaderConfig,
      frozenColumns,
      extendLastColumn,
      cellPaddingLeftSize,
      headerTopBorder,
      headerLeftBorder,
    ],
  );

  const headerGridData = useMemo(
    () => ({
      getColumnWidth,
      headerData: getHeaderData(tableColumns, headerConfig),
      cellPaddingLeftSize,
      headerTopBorder,
      headerLeftBorder,
      extendLastColumn,
      addWidthToLastColumn: isVerticalScrollVisable,
    }),
    [
      getColumnWidth,
      getHeaderData,
      headerConfig,
      tableColumns,
      extendLastColumn,
      cellPaddingLeftSize,
      headerTopBorder,
      headerLeftBorder,
      isVerticalScrollVisable,
    ],
  );

  const frozenGridData = useMemo(
    () => ({
      tableRows,
      tableColumns: frozenColumns,
      getColumnWidth: index => frozenColumns[index].width,
      cellPaddingLeftSize,
      rowClickable: !!previewColumn,
      activeRowIndex,
      setActiveRowIndex,
      hovering,
      manualHovering,
      customHoverClass,
      formatCellConditionally,
      handleRowRightClick,
      addHeightToLastRow: isHorizontalScrollVisable && isVerticalScrollVisable,
    }),
    [
      tableRows,
      frozenColumns,
      cellPaddingLeftSize,
      previewColumn,
      activeRowIndex,
      hovering,
      manualHovering,
      customHoverClass,
      formatCellConditionally,
      handleRowRightClick,
      isHorizontalScrollVisable,
      isVerticalScrollVisable,
    ],
  );

  const mainGridData = useMemo(
    () => ({
      tableRows,
      tableColumns,
      getColumnWidth,
      cellPaddingLeftSize,
      rowClickable: !!previewColumn,
      activeRowIndex,
      setActiveRowIndex,
      hovering,
      manualHovering,
      customHoverClass,
      formatCellConditionally,
      handleRowRightClick,
    }),
    [
      tableRows,
      tableColumns,
      getColumnWidth,
      cellPaddingLeftSize,
      previewColumn,
      activeRowIndex,
      hovering,
      manualHovering,
      customHoverClass,
      formatCellConditionally,
      handleRowRightClick,
    ],
  );

  return (
    <div className="d-flex">
      <div ref={outerContainerRef} style={{ width }}>
        <div className="d-flex">
          {frozenColumns.length > 0 && (
            <div
              style={{ height: headerHeight * headerRowCount, width: frozenContentWidth }}
              className="bo-table-header-container"
            >
              {frozenHeaderGridData?.headerData?.map((_, rowIdx) => (
                <HeaderRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={rowIdx}
                  style={{ height: headerHeight }}
                  index={rowIdx}
                  data={frozenHeaderGridData}
                />
              ))}
            </div>
          )}
          <div
            ref={headerGridRef}
            style={{ height: headerHeight * headerRowCount, width: mainGridWidth }}
            className="bo-table-header-container"
          >
            {headerGridData?.headerData?.map((_, rowIdx) => (
              <HeaderRow
                // eslint-disable-next-line react/no-array-index-key
                key={rowIdx}
                style={{ height: headerHeight }}
                index={rowIdx}
                data={headerGridData}
              />
            ))}
          </div>
        </div>
        <div className="d-flex">
          {frozenColumns.length > 0 && (
            <VariableSizeList
              ref={frozenGridRef}
              height={height - headerHeight * headerRowCount}
              width={frozenContentWidth}
              itemCount={tableRows.length}
              itemSize={getRowHeight || (() => rowHeight)}
              estimatedItemSize={estimatedRowHeight}
              itemData={frozenGridData}
              itemKey={(index, data) => `${data.tableRows[index][rowKey]}`}
              className="bo-table-group-container"
              overscanCount={overscanRowCount}
            >
              {Row}
            </VariableSizeList>
          )}
          {virtualization ? (
            <VariableSizeList
              ref={mainGridRef}
              outerRef={mainGridContainerRef}
              height={height - headerHeight * headerRowCount}
              width={mainGridWidth}
              itemCount={tableRows.length}
              itemSize={getRowHeight || (() => rowHeight)}
              estimatedItemSize={estimatedRowHeight}
              itemData={mainGridData}
              itemKey={(index, data) => `${data.tableRows[index][rowKey]}`}
              className="bo-table-container"
              overscanCount={overscanRowCount}
            >
              {Row}
            </VariableSizeList>
          ) : (
            <div
              ref={mainGridContainerRef}
              style={{ height: height - headerHeight * headerRowCount, width: mainGridWidth }}
              className="bo-table-container overflow-auto"
            >
              <div className="d-table">
                {tableRows.map((row, rowIdx) => (
                  <Row
                    key={row[rowKey]}
                    style={{ height: getRowHeight ? getRowHeight(rowIdx) : rowHeight }}
                    index={rowIdx}
                    data={mainGridData}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {activeRowIndex !== null && !!previewColumn && (
        <div style={{ width: previewColumn.width, height }}>
          <div
            style={{
              width: previewColumn.width,
              height: headerHeight,
            }}
            className={`bo-table-cell fw-bold px-1 overflow-hidden border-bottom border-start ${
              headerTopBorder ? 'border-top' : ''
            }`}
          >
            {previewColumn.header}
          </div>
          <div
            style={{ width: previewColumn.width, height: height - headerHeight * headerRowCount }}
            // eslint-disable-next-line max-len
            className="bo-table-cell bo-table-container align-items-start overflow-auto border-start"
          >
            {previewColumn.renderValue
              ? previewColumn.renderValue(
                  tableRows[activeRowIndex][previewColumn.key],
                  tableRows[activeRowIndex],
                )
              : tableRows[activeRowIndex][previewColumn.key]}
          </div>
        </div>
      )}
    </div>
  );
}

export default VirtualizedList;
