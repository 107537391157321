import { useState } from 'react';
import { Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '../../../../../components/Spinner';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { faCheckCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { countryCodes } from '../../../../../utils/countryCodes';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';

function ConsumerPopoverPhoneNumberForm({
  consumerId,
  consumerPhoneCc,
  consumerPhoneNdc,
  consumerPhoneSn,
  consumerIsPhoneVerified,
  setConsumer,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [phoneFields, setPhoneFields] = useState({
    phoneCc: consumerPhoneCc,
    phoneNdc: consumerPhoneNdc,
    phoneSn: consumerPhoneSn,
  });
  const [mfaCode, setMfaCode] = useState('');

  const [initVerified, setInitVerified] = useState(false);

  const initPhoneVerification = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }
    const config = {
      token,
      url: `${apiOriginConsumer}/init_phone_verification`,
      method: 'POST',
      data: JSON.stringify({ consumerId, ...phoneFields }),
    };

    try {
      await makeRequest(config);

      setInitVerified(true);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const updatePhone = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/update_phone`,
      method: 'POST',
      data: JSON.stringify({ consumerId, ...phoneFields }),
    };

    try {
      const response = await makeRequest(config);

      if (response === 'ok') {
        setConsumer(prevConsumer => ({ ...prevConsumer, ...phoneFields }));

        setInitVerified(false);
        setMfaCode('');
        setPhoneFields({
          phoneCc: consumerPhoneCc,
          phoneNdc: consumerPhoneNdc,
          phoneSn: consumerPhoneSn,
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const updatePhoneVerification = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }
    const config = {
      token,
      url: `${apiOriginConsumer}/update_phone_verification`,
      method: 'POST',
      data: JSON.stringify({ consumerId, ...phoneFields, code: mfaCode }),
    };

    try {
      const response = await makeRequest(config);

      if (response.isVerified) {
        await updatePhone();
      } else {
        toast.error('MFA code verification failed!');
        setSpinner(false);
      }
    } catch (error) {
      toast.error(error.message);
      setSpinner(false);
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setInitVerified(false);
    setMfaCode('');
    setPhoneFields({
      phoneCc: consumerPhoneCc,
      phoneNdc: consumerPhoneNdc,
      phoneSn: consumerPhoneSn,
    });
  };

  return (
    <>
      <Label className="bo-consumer-input-label">Phone Number</Label>
      <Button
        id="popover_phone_number_form"
        className="bo-consumer-text-input-container"
        onClick={handleToggle}
      >
        <div className="d-flex align-items-center w-100">
          <Input
            className="bo-consumer-text-input bo-w-150"
            type="text"
            readOnly
            value={`+${consumerPhoneCc} (${consumerPhoneNdc}) ${consumerPhoneSn.slice(
              0,
              3,
            )}-${consumerPhoneSn.slice(3)}`}
          />
          <FontAwesomeIcon
            icon={consumerIsPhoneVerified ? faCheckCircle : faQuestionCircle}
            color={consumerIsPhoneVerified ? 'green' : 'orange'}
            size="sm"
          />
        </div>
        <div className="bo-consumer-input-button-container">
          <div className="bo-consumer-input-button-icon">
            <FontAwesomeIcon icon={faPencil} color="#5946A4" size="sm" />
          </div>
        </div>
      </Button>
      <Popover
        target="popover_phone_number_form"
        isOpen={isOpen}
        placement="bottom-start"
        toggle={handleToggle}
        offset={[0, 0]}
        fade={false}
        hideArrow
      >
        <PopoverBody className="bo-consumer-popover-form-menu">
          <Spinner visible={spinner} size={36}>
            <div className="d-flex gap-2">
              <div>
                <Label className="bo-consumer-popover-form-menu-label">Country Code</Label>
                <Input
                  type="select"
                  className="bo-consumer-popover-form-menu-input bo-w-100"
                  value={phoneFields.phoneCc}
                  onChange={e => setPhoneFields({ ...phoneFields, phoneCc: e.target.value })}
                  disabled={initVerified}
                >
                  {[...new Set(countryCodes.map(x => x.countryCodes).flat())].map(countryCode => (
                    <option key={countryCode} value={countryCode}>
                      +{countryCode}
                    </option>
                  ))}
                </Input>
              </div>
              <div>
                <Label className="bo-consumer-popover-form-menu-label">Area Code</Label>
                <NumericFormat
                  className="bo-consumer-popover-form-menu-input bo-w-120"
                  customInput={Input}
                  isAllowed={values => values.formattedValue.length <= 3}
                  allowNegative={false}
                  decimalScale={0}
                  value={phoneFields.phoneNdc}
                  onChange={e => setPhoneFields({ ...phoneFields, phoneNdc: e.target.value })}
                  disabled={initVerified}
                  allowLeadingZeros
                />
              </div>
              <div>
                <Label className="bo-consumer-popover-form-menu-label">Number</Label>
                <NumericFormat
                  className="bo-consumer-popover-form-menu-input bo-w-120"
                  customInput={Input}
                  isAllowed={values => values.formattedValue.length <= 12}
                  allowNegative={false}
                  decimalScale={0}
                  value={phoneFields.phoneSn}
                  onChange={e => setPhoneFields({ ...phoneFields, phoneSn: e.target.value })}
                  disabled={initVerified}
                  allowLeadingZeros
                />
              </div>
            </div>
            {initVerified && (
              <div className="mt-1">
                <Label className="bo-consumer-popover-form-menu-label">MFA Code</Label>
                <div className="d-flex gap-3">
                  <PatternFormat
                    className="bo-consumer-popover-form-menu-input bo-w-120"
                    type="text"
                    name="mfaCode"
                    id="mfCode"
                    value={mfaCode || ''}
                    customInput={Input}
                    format="######"
                    mask="_"
                    onChange={e => setMfaCode(e.target.value)}
                  />
                  <Button
                    className="bo-consumer-inline-button text-decoration-none"
                    onClick={initPhoneVerification}
                    disabled={spinner}
                  >
                    Re-send
                  </Button>
                </div>
              </div>
            )}
            {initVerified ? (
              <div className="mt-2 d-flex justify-content-between">
                <Button
                  className="bo-consumer-button"
                  onClick={updatePhoneVerification}
                  disabled={mfaCode.length !== 6}
                >
                  Save
                </Button>
                <Button className="bo-consumer-button" onClick={handleToggle}>
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="mt-2 d-flex justify-content-between">
                <Button
                  className="bo-consumer-button"
                  onClick={initPhoneVerification}
                  disabled={!phoneFields.phoneCc || !phoneFields.phoneNdc || !phoneFields.phoneSn}
                >
                  Verify & Save
                </Button>
                <Button className="bo-consumer-button" onClick={handleToggle}>
                  Close
                </Button>
              </div>
            )}
          </Spinner>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default ConsumerPopoverPhoneNumberForm;
