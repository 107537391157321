import { Row, Col } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import ReactJsonEditor from '../../../../../components/ReactJsonEditor';
import '../../../../../assets/jsonEditor.css';

function RulesEditor({ spinner, setRef }) {
  return (
    <Row className="align-items-start">
      <Col md={12}>
        <Spinner visible={spinner} text="">
          <ReactJsonEditor getJsonEditor={jsonEditor => setRef(jsonEditor)} />
        </Spinner>
      </Col>
    </Row>
  );
}

export default RulesEditor;
