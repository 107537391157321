import { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { Badge, Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { Spinner } from '../../../../../components/Spinner';

function ConsumerPopoverAuthenticateForm({ consumerId, consumerMfaChannel }) {
  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [mfaChannel, setMfaChannel] = useState(consumerMfaChannel);
  const [codeSent, setCodeSent] = useState(false);
  const [mfaCode, setMfaCode] = useState('');
  const [isVerified, setIsVerified] = useState(null);

  const sendMfa = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/send_mfa_by_id`,
      method: 'POST',
      data: JSON.stringify({ consumerId, mfaChannel, mfaUseCase: 'AUTH' }),
    };

    try {
      const response = await makeRequest(config);

      setCodeSent(true);
      setIsVerified(null);
      setMfaCode('');

      toast.success(`MFA code has been sent by ${response.mfaChannel} to ${response.mfaMask}`);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const validate = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/verify_mfa_code`,
      method: 'POST',
      data: JSON.stringify({ consumerId, mfaChannel, mfaCode }),
    };

    try {
      const response = await makeRequest(config);

      setIsVerified(response.isVerified);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const handleToggle = () => {
    setMfaChannel(consumerMfaChannel);
    setCodeSent(false);
    setMfaCode('');
    setIsVerified(null);
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button
        id="popover_authenticate"
        className="bo-consumer-inline-button"
        onClick={handleToggle}
      >
        Authenticate
      </Button>
      <Popover
        target="popover_authenticate"
        isOpen={isOpen}
        placement="bottom-start"
        toggle={handleToggle}
        offset={[0, 6]}
        fade={false}
        hideArrow
      >
        <PopoverBody className="bo-consumer-popover-form-menu">
          <Spinner visible={spinner} size={36}>
            <Label className="bo-consumer-popover-form-menu-label">MFA Channel</Label>
            <div className="d-flex gap-3 bo-w-250">
              <Input
                type="select"
                className="bo-consumer-popover-form-menu-input bo-w-150"
                value={mfaChannel}
                onChange={e => setMfaChannel(e.target.value)}
              >
                <option value="EMAIL">E-mail</option>
                <option value="SMS">SMS</option>
              </Input>
              <Button
                className="bo-consumer-inline-button text-decoration-none"
                onClick={sendMfa}
                disabled={spinner}
              >
                Send
              </Button>
            </div>
            <Label className="bo-consumer-popover-form-menu-label mt-2">MFA Code</Label>
            <div className="d-flex gap-3 bo-w-250">
              <PatternFormat
                className="bo-consumer-popover-form-menu-input bo-w-150"
                type="text"
                value={mfaCode || ''}
                customInput={Input}
                format="######"
                mask="_"
                onChange={e => setMfaCode(e.target.value)}
                readOnly={!codeSent}
              />
              <Button
                className="bo-consumer-inline-button text-decoration-none"
                disabled={spinner || !codeSent}
                onClick={validate}
              >
                Verify
              </Button>
            </div>
            <div className="bo-h-30">
              {isVerified !== null && (
                <Badge color={isVerified ? 'success' : 'danger'} className="bo-inline-badge mt-2">
                  {isVerified ? 'Verification Succeeded' : 'Verification Failed'}
                </Badge>
              )}
            </div>
            <div className="mt-2 d-flex justify-content-center">
              <Button className="bo-consumer-button" onClick={handleToggle}>
                Close
              </Button>
            </div>
          </Spinner>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default ConsumerPopoverAuthenticateForm;
